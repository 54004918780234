@import '~qs-assets/Colors';

.custom-html-builder-editor-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.custom-html-builder-modal-overlay {
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;

  .custom-html-builder-modal {
    background-color: #242C36;
    border-radius: 0px;
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    padding: 0px;
    margin: 0px auto;
    z-index: 1001;

    .custom-html-builder-modal-close-button {
      position: absolute;
      display: flex;
      align-self: flex-end;
      height: 36px;
      min-height: 36px;
      max-height: 36px;
      width: 36px;
      min-width: 36px;
      max-width: 36px;
      padding: 4px;
      margin: 0px;
      top: 8.5px;
      right: 6px;
      z-index: 1003;
      border: none;
      outline: none;
      box-shadow: none;
      border-radius: 50%;
      cursor: pointer;
      background-color: transparent;

      &:hover,
      &:focus,
      &:active,
      &:disabled {
        background-color: transparent;
      }

      .custom-html-builder-modal-close-icon {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        fill: $primary-color;
      }
    }

    .custom-html-builder-tiles-list-container {
      height: calc(100% - 36px - 2 * 8.5px);
      width: 100%;
      padding: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;

      .custom-html-builder-tiles-list-label {
        font-size: 20px;
        font-weight: 400;
        color: $primary-color;
        text-align: center;
        padding: 0px 24px;
      }

      .custom-html-builder-tiles-list-items {
        overflow: auto;
        display: flex;
        padding: 0px 24px 24px 24px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 24px;

        .custom-html-builder-tiles-list-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 12px;
          text-decoration: none;
          box-shadow: none;
          cursor: pointer;
          outline: none;
          border: none;

          .custom-html-builder-tiles-list-item-icon {
            height: 106px;
            width: 106px;
            padding: 20px;
            border: 1px solid $header-color;
            border-radius: 5px;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }

          .custom-html-builder-tiles-list-item-label {
            font-size: 16px;
            font-weight: 500;
            color: $header-color;
            text-align: center;
          }
        }
      }
    }

    .custom-html-builder-editor-save-button {
      position: fixed;
      bottom: 20px;
      right: 20px;
      height: 40px;
      width: fit-content;
      max-width: 300px;
      color: $primary-color;
      border-radius: 5px;
      z-index: 1001;
      box-shadow: 0px 0px 10px 1px rgba(26, 32, 39, 0.1);
      outline: none;
      border: none;
      padding: 0px 12px;
      margin: 0px;
      font-size: 15px;
      text-transform: uppercase;

      svg {
        height: 20px;
        width: 20px;
        fill: $primary-color;
      }

      svg,
      .loader {
        margin-left: 6px;
      }
    }
  }

  .custom-html-builder-editor {
    border: none;
    outline: none;
    box-shadow: none;
    height: 100%;
    width: 100%;
    overflow: auto;

    .gjs-one-bg {
      background-color: $settings-panel-background;
    }

    .gjs-two-color {
      color: $primary-color;
    }

    .gjs-three-bg {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .gjs-color-warn {
      color: $header-color;
    }

    .gjs-field,
    .gjs-pn-btn.gjs-pn-active {
      background-color: $settings-panel-active-background;
    }

    .gjs-pn-views,
    .gjs-category-open,
    .gjs-block-category.gjs-open,
    .gjs-sm-sector.gjs-sm-open,
    .gjs-category-title,
    .gjs-layer-title,
    .gjs-block-category .gjs-title,
    .gjs-sm-sector-title {
      border-color: $settings-panel-border-color;
    }

    .gjs-four-color,
    .gjs-four-color-h,
    .gjs-four-color-h:hover,
    .gjs-four-color-h:focus,
    .gjs-four-color-h:active,
    .gjs-four-color-h:focus-within {
      color: $header-color !important;
    }

    .gjs-selected {
      outline-color: $header-color !important;
    }

    .gjs-toolbar,
    .gjs-com-badge,
    .gjs-badge {
      background-color: $header-color !important;
    }

    .gjs-category-title,
    .gjs-layer-title,
    .gjs-block-category .gjs-title,
    .gjs-sm-sector-title {
      background-color: $settings-panel-background;
    }

    .gjs-sm-field.gjs-sm-composite,
    .gjs-sm-composite.gjs-clm-select,
    .gjs-sm-composite.gjs-clm-field {
      border-color: $settings-panel-border-color;
      border-radius: 5px;
    }

    .btn-cl,
    .gjs-am-close,
    .gjs-mdl-btn-close {
      opacity: 1;
    }

    .gjs-clm-tags {
      padding-top: 5px;
      padding-bottom: 7.5px;
      padding-left: 15px;
      padding-right: 15px;

      .gjs-clm-header {
        margin-top: 12.5px;
        margin-bottom: 17.5px;

        .gjs-clm-header-label {
          font-size: 15px;
        }

        .gjs-clm-header-status {
          .gjs-field {
            input,
            select {
              border: 1px solid $settings-panel-border-color;
              border-radius: 5px;
            }

            .gjs-sel-arrow {
              right: 5px;
            }

            select {
              white-space: normal;
              padding-right: 25px;
            }
          }
        }
      }

      .gjs-field,
      #gjs-clm-states,
      .gjs-clm-sels-info {
        font-size: 15px;
      }

      #gjs-clm-tags-field {
        margin-bottom: 12.5px;
        border: 1px solid $settings-panel-border-color;
        border-radius: 5px;
        min-height: 39px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        #gjs-clm-tags-c {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;

          .gjs-clm-tag {
            margin: 0px;
          }

          &:empty {
            display: none;
          }
        }

        .gjs-clm-tags-btn {
          &.gjs-clm-tags-btn__add {
            width: 27px;
            height: 27px;
            margin: 0px;
          }
        }
      }
    }

    .gjs-clm-sel-id,
    .gjs-field input,
    .gjs-field select,
    .gjs-field textarea {
      font-size: 15px;
    }

    .gjs-field input,
    .gjs-field select {
      padding: 10px;
    }

    .gjs-clm-tags+.gjs-sm-sector {
      &.no-select {
        &:nth-of-type(2) {
          .gjs-sm-sector-title {
            border-top: 1px solid $settings-panel-border-color;
            cursor: auto;
          }

          .gjs-sm-sector-title + .gjs-sm-properties {
            display: block;
            overflow: hidden;
            padding-top: 0px;
            padding-bottom: 0px;
            padding-left: 10px;
            padding-right: 10px;

            .gjs-trt-traits {
              padding-top: 10px;
              padding-bottom: 10px;

              .gjs-trt-trait__wrp-data-product-grid-tile-element-type,
              .gjs-trt-trait__wrp-data-product-list-tile-element-type,
              .gjs-trt-trait__wrp-data-product-magazine-tile-element-type,
              .gjs-trt-trait__wrp-data-catalogue-grid-tile-element-type {
                .gjs-trt-trait--select {
                  flex-direction: column;
                  padding-top: 7.5px;
                  padding-bottom: 10px;

                  .gjs-label-wrp {
                    width: 100%;
                    min-width: 100%;
                    padding-left: 20px;
                    white-space: normal;

                    .gjs-label {
                      .gjs-label-info {
                        margin-left: 10px;
                      }
                    }
                  }

                  .gjs-field-wrp {
                    width: 100%;
                    min-width: 100%;
                    margin-top: 10px;
                  }
                }
              }

              .gjs-trt-trait__wrp {
                .gjs-trt-trait {
                  .gjs-field-wrp {
                    .gjs-field {
                      input,
                      select {
                        border: 1px solid $settings-panel-border-color;
                        border-radius: 5px;
                      }

                      &.gjs-field-select {
                        .gjs-sel-arrow {
                          .gjs-d-s-arrow {
                            right: 10px;
                          }
                        }

                        select {
                          white-space: normal;
                          padding-right: 25px;
                        }
                      }
                    }
                  }

                  &.gjs-trt-trait--color {
                    .gjs-field-color {
                      .gjs-field-colorp {
                        padding: 0px;
                        border-top: 1px solid $settings-panel-border-color;
                        border-bottom: 1px solid $settings-panel-border-color;
                        border-right: 1px solid $settings-panel-border-color;
                        border-radius: 5px;

                        .gjs-checker-bg,
                        .gjs-field-colorp-c,
                        .gjs-field-color-picker {
                          border-radius: 0px 4px 4px 0px;
                        }
                      }
                    }
                  }



                  &.gjs-trt-trait--number {
                    .gjs-field-wrp--number {
                      .gjs-field-number {
                        .gjs-field-int {
                          display: flex;
                          align-items: center;
                          border: 1px solid $settings-panel-border-color;
                          border-radius: 5px;

                          .gjs-input-holder {
                            width: calc(100% - 35px - 25px);

                            input {
                              border: none;
                              padding-right: 0px;
                            }
                          }

                          .gjs-field-units,
                          .gjs-field-arrows {
                            position: unset;
                          }

                          .gjs-field-units {
                            width: 35px;

                            .gjs-input-unit {
                              border: none;
                              padding-right: 0px;
                              text-align: center;
                            }
                          }

                          .gjs-field-arrows {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            row-gap: 7.5px;
                            width: 25px;

                            .gjs-field-arrow-u,
                            .gjs-field-arrow-d {
                              position: unset;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .gjs-sm-sector,
    .gjs-sm-sectors {
      &.no-select {
        .gjs-sm-sector-title {
          font-size: 15px;
          padding-left: 15px;
          padding-right: 15px;
        }

        .gjs-trt-traits {
          .gjs-trt-trait {
            padding-left: 5px;
            padding-right: 5px;

            .gjs-label-wrp {
              padding-right: 15px;

              .gjs-label {
                font-size: 15px;
              }
            }

            .gjs-field-wrp {

              .gjs-field input,
              .gjs-field select {
                font-size: 15px;
              }
            }
          }
        }
      }

      .gjs-sm-sector-title,
      .gjs-sm-properties,
      .gjs-trt-traits {
        &:empty {
          display: none;
        }
      }
    }

    .gjs-sm-sectors {
      .gjs-sm-sector {
        background-color: $settings-panel-selected-background;

        .gjs-sm-sector-title {
          padding-left: 10px;
          padding-right: 10px;
        }

        .gjs-sm-sector-title+.gjs-sm-properties {
          padding-left: 7.5px;
          overflow: auto;

          .gjs-sm-property {
            margin-bottom: 10px;
            padding-left: 10px;
            padding-right: 10px;

            .gjs-sm-label {
              margin-bottom: 10px;

              .gjs-sm-icon {
                font-size: 15px;
              }
            }

            .gjs-fields {
              .gjs-field {
                font-size: 15px;
              }
            }

            &.gjs-sm-radio {
              .gjs-fields {
                .gjs-field {
                  &.gjs-field-radio {
                    .gjs-radio-items {
                      padding-top: 0px;
                      padding-bottom: 0px;

                      .gjs-radio-item {
                        .gjs-sm-icon {
                          height: 29px;

                          &.icons-flex {
                            margin: 0px auto;
                            background-size: 20px 20px !important;
                            width: auto;
                          }

                          &:before {
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &.gjs-sm-select {
              .gjs-select {
                .gjs-sel-arrow {
                  .gjs-d-s-arrow {
                    right: 10px;
                  }
                }

                select {
                  padding-right: 25px;
                  white-space: normal;
                  border: 1px solid $settings-panel-border-color;
                  border-radius: 5px;
                }
              }
            }

            &.gjs-sm-integer,
            &.gjs-sm-number {
              .gjs-field-integer {
                display: flex;
                align-items: center;
                border: 1px solid $settings-panel-border-color;
                border-radius: 5px;

                .gjs-input-holder {
                  width: calc(100% - 35px - 25px);

                  input {
                    padding-right: 0px;
                  }
                }

                .gjs-field-units,
                .gjs-field-arrows {
                  position: unset;
                }

                .gjs-field-units {
                  width: 35px;

                  .gjs-input-unit {
                    padding-right: 0px;
                    text-align: center;
                  }
                }

                .gjs-field-arrows {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  row-gap: 7.5px;
                  width: 25px;

                  .gjs-field-arrow-u,
                  .gjs-field-arrow-d {
                    position: unset;
                  }
                }
              }
            }

            &.gjs-sm-slider {
              .gjs-fields {
                border: 1px solid $settings-panel-border-color;
                border-radius: 5px;

                .gjs-field-range,
                .gjs-field-integer {
                  border-radius: 4px;
                }

                .gjs-field-integer {
                  .gjs-field-arrows {
                    right: 7.5px;
                  }
                }
              }
            }

            &.gjs-sm-stack {
              .gjs-fields {
                .gjs-sm-stack {
                  #gjs-sm-add {
                    top: -27.5px;
                  }

                  .gjs-field {
                    margin: 0px;
                    padding: 0px;
                    min-height: 39px;

                    &:not(.gjs-select) {
                      border: 1px solid $settings-panel-border-color;
                      border-radius: 5px;
                    }

                    .gjs-sm-layer {
                      margin: 0px;
                      padding: 10px 5px;
                      min-height: 26.5px;

                      .gjs-sm-label-wrp {
                        #gjs-sm-label {
                          margin: 0px 10px;
                        }

                        #gjs-sm-preview-box {
                          height: 17.5px;
                          width: 17.5px;
                          margin-right: 7.5px;

                          .gjs-sm-layer-preview-cnt {
                            &:after {
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              line-height: unset;
                            }
                          }
                        }

                        #gjs-sm-close-layer {
                          opacity: 1;
                        }
                      }

                      &.gjs-sm-active {
                        .gjs-sm-label-wrp {
                          padding: 0px 5px 0px 7.5px;
                        }
                      }

                      &:not(.gjs-sm-active) {
                        display: flex;
                        justify-content: space-between;
                        height: 100%;
                        width: calc(100% - 20px);
                        padding: 10px;
                        min-height: 16.5px;

                        .gjs-sm-label-wrp {
                          width: 100%;
                        }
                      }
                    }
                  }
                }
              }
            }

            &.gjs-sm-base {
              .gjs-field {
                input {
                  border: 1px solid $settings-panel-border-color;
                  border-radius: 5px;
                }
              }
            }

            &.gjs-sm-color {
              .gjs-field-color {
                border: 1px solid $settings-panel-border-color;
                border-radius: 5px;

                .gjs-field-colorp {
                  padding: 0px;

                  .gjs-checker-bg,
                  .gjs-field-colorp-c,
                  .gjs-field-color-picker {
                    border-radius: 0px 4px 4px 0px;
                  }
                }
              }
            }

            &.gjs-sm-radio {
              &.gjs-sm-property--full {
                .gjs-field-radio {
                  .gjs-radio-items {
                    border: 1px solid $settings-panel-border-color;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
        }

        &.gjs-sm-sector__general {
          border-top: 1px solid $settings-panel-border-color;
        }
      }
    }

    .gjs-editor {
      height: 100%;
      width: 100%;
      font-family: sans-serif;

      .gjs-block {
        justify-content: center;

        .gjs-block__media {
          max-height: 52px;
          width: 100%;

          svg,
          img,
          picture {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }

        .gjs-block-label {
          font-size: 14px;
        }
      }

      .symbols__wrapper {
        .symbols__empty {
          font-size: 15px;
        }
      }

      .gjs-cv-canvas {
        top: calc(52px + 1px);
        width: calc(100% - 350px);
        height: calc(100% - 52px - 1px);

        .gjs-cv-canvas__frames {
          .gjs-frames {
            height: 100%;
            width: 100%;

            .gjs-frame-wrapper {
              height: 100%;
              max-height: 100%;
              width: 100%;
              max-width: 100%;
              transition: all 0.2s ease-in-out;

              .gjs-frame {
                height: 100%;
                width: 100%;
                display: block;
                border: none;
                outline: none;
                box-shadow: none;
              }
            }
          }
        }
      }

      .gjs-pn-panels {
        height: 52px;
        width: 100%;

        .gjs-pn-panel {
          .gjs-pn-buttons {
            .gjs-pn-btn {
              &:last-child {
                margin-right: 0px;
              }
            }
          }

          &.gjs-pn-devices-c {
            height: calc(52px + 1px);
            width: 110px;
            border-bottom: 1px solid $settings-panel-border-color;
            border-right: 1px solid $settings-panel-border-color;

            .gjs-pn-buttons {
              height: 100%;
            }
          }

          &.gjs-pn-commands {
            display: none;
          }

          &.gjs-pn-options {
            height: calc(52px + 1px);
            width: calc(100% - 110px - 350px);
            right: 350px;
            padding: 0px 5px 0px 5px;
            border-bottom: 1px solid $settings-panel-border-color;

            .gjs-pn-buttons {
              height: 52px;
              justify-content: flex-end;

              .gjs-pn-btn {
                height: 52px;
                width: 30px;
                padding: 5px 0px 5px 0px;
                display: flex;
                justify-content: center;
                align-items: center;

                &:first-child {
                  width: 35px;
                  padding-left: 5px;
                  padding-right: 5px;
                  border-left: 1px solid $settings-panel-border-color;
                  border-top-left-radius: 0px;
                  border-bottom-left-radius: 0px;
                }
              }
            }
          }

          &.gjs-pn-views {
            height: calc(52px + 1px);
            width: 350px;
            border-bottom-width: 1px;
            border-left: 1px solid $settings-panel-border-color;

            .gjs-pn-buttons {
              height: 100%;
              justify-content: flex-start;
            }
          }

          &.gjs-pn-views-container {
            width: 350px;
            height: calc(100% - 52px - 1px);
            padding-top: 0px;
            padding-bottom: calc(40px + 2 * 20px);
            margin-top: calc(52px + 1px);
            border-left: 1px solid $settings-panel-border-color;
          }

          .gjs-block-categories {
            .gjs-block {
              background-color: $settings-panel-active-background;
            }

            .gjs-block-category {
              .gjs-title {
                font-size: 15px;
              }

              .gjs-blocks-c {
                padding-bottom: 5px;
                padding-left: 2.5%;
                padding-right: 2.5%;
              }
            }
          }

          .gjs-blocks-no-cat {

            .gjs-block,
            .gjs-block-section {
              background-color: $settings-panel-active-background;
            }
          }
        }
      }
    }

    .sp-container {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}