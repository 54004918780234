$tagBackgroundColor: #ffffff;
$tagTextColor: #232b36;
$unselectedTag: #7b8ea9;
$tagButtonColor: #73e387;
$containerbackground: #232b36;

.productTagsSelector {
  .tagsTitle {
    margin-top: 5px;
    color: #8497b1;
    font-size: 14px;
    font-weight: 800;
  }

  .unselectedTagsScrollContainer {
    display: flex;
    flex-flow: wrap;

    &::-webkit-scrollbar {
      display: none;
    }

    .unselectedTag {
      cursor: pointer;
      padding: 5px 10px;
      border-radius: 15px;
      border: 1px dashed $unselectedTag;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      position: relative;

      .unselectedTagText {
        color: $unselectedTag;
        font-size: 14px;
        font-weight: 600;
      }

      .plus {
        display: none;
        background-color: rgb(42, 160, 71);
        border-radius: 50%;
        color: #fff;
        font-size: 14px;
        margin-left: 5px;
        width: 17px;
        height: 17px;
        text-align: center;
        margin-right: -4px;
        position: absolute;
        right: 10px;
      }

      &:hover {
        background-color: $tagBackgroundColor;
        border: 1px solid transparent;

        .unselectedTagText {
          color: $tagTextColor;
        }

        .plus {
          display: block;
        }
      }
    }

    .productTagsSelectorFooter {
      width: 30px;
    }
  }

  .bulkSelectedTagsLoader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    min-height: 40px;
  }

  .selectedTagsContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    min-height: 35px;

    .selectedTagsScrollContainer {
      display: flex;
      flex-flow: wrap;
      flex: 1 1;

      &::-webkit-scrollbar {
        display: none;
      }

      .selectedTag {
        padding: 5px 15px;
        border-radius: 15px;
        background-color: $tagBackgroundColor;
        align-self: center;
        white-space: nowrap;
        margin-top: 10px;

        .selectedTagText {
          color: $tagTextColor;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .tagButton {
      flex: 0 0;
      margin: 10px 0px 0px 10px;
      background-color: $containerbackground;
    }
  }

  .addTagsContainer {
    margin-left: 30px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .removeMargin {
    margin-left: 0px !important;
  }

  .tagButton {
    cursor: pointer;
    overflow: visible;
    background-color: transparent;
    border: solid $tagBackgroundColor;
    border-width: 1px;
    padding: 10px 15px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .tagButtonIcon {
      width: 16px;
      height: 16px;
      fill: $tagBackgroundColor;
    }

    .tagButtonText {
      margin-left: 5px;
      color: $tagBackgroundColor;
      font-size: 15px;
    }

    &:hover {
      background-color: $tagBackgroundColor;
      border-color: $tagBackgroundColor;
      color: #000000;

      .tagButtonIcon {
        fill: #000000;
      }

      .tagButtonText {
        color: #000000;
        text-transform: capitalize;
      }
    }
  }

  .productTagsSelectorSeparator {
    width: 10px;
  }
}
