.default-profile-logo-container {
  background-color: white;
  border-radius: inherit;
  width: 25px;
  height: 25px;
  border: 1px solid black;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-logo {
  border-radius: 4px;
}

.profile--details {
  margin-bottom: 5px;
}
