.qs-click-handler--container {
  color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  cursor: pointer;
  flex-shrink: 0;

  .qs-click-handler__children--container {
    flex-grow: 1;
  }

  .qs-click-handler__loader-container {
    flex-shrink: 0;
    padding-right: 10px;
  }
}
