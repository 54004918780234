$settingsPadding: 15px;

.settings-section-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: transparent;
  padding: $settingsPadding 25px 0px;
  align-items: flex-start;
  justify-content: space-between;

  & > :nth-child(2) {
    margin-left: 20px;
  }

  .icon-container {
    width: 22px;
    flex-shrink: 0;
    border-radius: 30px;
    margin-top: 4px;

    .invert {
      -webkit-filter: invert(1);
      filter: invert(1);
    }

    & > img {
      width: inherit;
    }
  }

  .settings-section-text-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #232b36;
    text-align: left;
    padding-bottom: $settingsPadding;
    padding-right: 5px;

    .settings-section-text--title {
      color: white;
      margin: 0px;
      margin-bottom: 10px;
      font-size: 1.1rem;
    }

    .settings-section-text--description {
      color: #9caabd;
      text-align: left;
      font-size: 0.9rem;
    }
  }
}
