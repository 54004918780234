$order-bg-color: #1d232b;
$font-color: #ffffff;
$dark-border-color: #303944;
$darker-bg-color: #0f141a;
$darkest-bg-color: #101419;
$faint-text-color: #67717f;
$light-grey-color: #bbc2cd;
$primary-green-color: #2ec07d;
$bright-green-color: #35e483;
$mid-bright-green-color: #35d29c;
$dark-green-color: #126b39;
$darker-hover-color: #0f1319;
$dark-hover-color: #161a21;
$dark-selected-color: #0f1319b5;
$bright-red-color: #f04b4b;
$editable-separator: 5px;
$order-inquiry-padding: 20px;
$order-z-index: 0;
$order-screen-header-margin: 10px;
$min-action-header-height: 40px;

.orderHeaderButtonIcon {
  fill: $font-color;
  width: 18px;
  height: 18px;
}

@mixin unread-style($margin-prop) {
  color: $font-color;
  background-color: $primary-green-color;
  #{$margin-prop}: 10px;
  border-radius: 3px;
  padding: 4px 10px;
  font-size: 12px;
  line-height: 11px;
}

.orderSingleViewAddMissingData {
  font-size: 12px;
  color: $faint-text-color;
  text-transform: uppercase;
}
