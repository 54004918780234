.renderMobileMainContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  overflow: auto;
}

.renderMobileMainContainer .title {
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 25px;
  padding-bottom: 10px;
}

.renderMobileMainContainer .description {
  padding: 10px 20px;
  text-align: center;
  padding-bottom: 65px;
}

.renderMobileMainContainer .logo {
  max-width: 150px;
  padding-bottom: 65px;
}

.renderMobileMainContainer .linkToWebsite {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: #6362f2;
  border: 1px solid #746fff;
  border-radius: 8px;
  padding: 6px 20px;
}

.renderMobileMainContainer .instructions {
  color: #6d6d6d;
  text-align: center;
  padding: 14px 15px;
}
