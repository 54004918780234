.product-demo-container {
  display: flex;
  flex-direction: column;

  .product-demo-text {
    margin-bottom: 10px;
  }

  .view-live-demo {
    display: flex;
    align-self: flex-start;
    border-radius: 5px;
    background-color: #2da56b;
    color: white;
    padding: 10px 15px;
    font-size: 14px;
  }
}
