$green: #489F6E;
$white: #ffffff;

.editAddress {
  &.title {
    color: $white;
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    padding: 24px 25px 38px;
  }

  &.inputContainer {
    display: flex;
    flex-direction: column;
    color: #c0c7d1;
    margin: 0px 25px 20px;

    .companyAddressLabel {
      font-size: 14px;
      font-weight: lighter;
      margin-bottom: 5px;
    }

    .inputBox {
      flex: 1;
      font-size: 16px;
      padding: 5px 0px;
      color: $white;
      outline: none;
      border: none;
      border-bottom: 1px solid #c0c7d1;
      background-color: transparent;
      
      &:focus {
        border-color: $white;
      }

      &::placeholder {
        color: #424f61;
      }

      &.inputBoxError {
        border-bottom: 1px solid #c72121;
      }
    }
  }

  &.error {
    color: #c72121;
    
    .addressErrorMessage{
      margin-top: 5px;
    }
  }

  &.buttonContainer {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    font-size: 16px;
    border-top: 1px solid #39424E;
    margin-top: 40px;

    .button {
      flex: 1;
      border: none;
      color: #758496;
      background-color: transparent;
      text-align: left;
      padding: 24px 25px;
      text-transform: uppercase;
    }

    .buttonReset {
      text-align: left;
      color: #758496;
    }

    .buttonPlain {
      color: $green;
      text-align: right;
    }
  }
}