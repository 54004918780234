.bulkDiscountModalHeader {
  font-size: 22px;
  margin-top: 13px;
}

.bulkDiscountInputbox {
  border-bottom: 2px solid #4da47a;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.bulkDiscountInputboxError {
  border-bottom: 2px solid #c72121;
}

.bulkDiscountInputbox .inputbox {
  /* border-bottom: 2px solid #4DA47A; */
  height: 50px;
  width: 100%;
  font-size: 22px;
  text-align: center;
}

.bulkDiscountInputbox .percentSymbol {
  font-size: 22px;
  text-align: center;
  margin-right: 5px;
}

.bulkDiscoutSetPercentage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 13px;
  font-size: 18px;
}

.bulkDiscoutSetPercentage .setPercentage {
  display: inline-block;
  border: 1.4px solid #4da47a;
  padding: 10px 20px;
  border-radius: 40px;
  color: #4da47a;
  font-weight: 500;
  font-size: 16px;
}

.bulkDiscoutSetPercentage .setPercentage:hover {
  background-color: #4da47a;
  color: white;
}

.bulkDiscoutSetPercentage .setPercentage:active {
  background-color: #4da47a;
  color: white;
}
