.resellModalContainer {
  text-align: center;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.75, 0.75, 0.75);
    transform: scale3d(0.75, 0.75, 0.75);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.8, 0.8, 0.8);
    transform: scale3d(0.8, 0.8, 0.8);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  animation-duration: 0.3s;
}

.resellModalContainer .catalogueInfoContainer {
  background-color: #47b683;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.catalogueInfoContainer .catalogueNameContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 30px 0px 30px;
  color: white;
}

.catalogueNameContainer .catalogueName {
  font-size: 22px;
  font-weight: bold;
}

.catalogueNameContainer .noOfProducts {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  font-family: Helvetica, sans-serif;
}

/*@font-face {*/
/*  font-family: 'loraItalics';*/
/*  src: local('loraItalics'), url(../../../Assets/fonts/Lora-Italic.ttf) format('woff');*/
/*}*/

.catalogueInfoContainer .companyName {
  display: flex;
  align-self: flex-start;
  padding: 2px 30px 15px 30px;
  font-size: 18px;
  color: white;
  /* TODO */
  /*font-family: 'loraItalics'*/
}

.catalogueInfoContainer .pictureContainer {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  padding: 0px 30px 30px 30px;
}

.resellModalContainer .decisionBoxContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-family: Helvetica, sans-serif;
  padding: 30px;
  padding-bottom: 30px;
  background-color: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.decisionBoxContainer .title {
  color: black;
  padding-bottom: 10px;
  font-size: 21px;
  font-weight: bold;
}

.resellModalContainer .subtitle {
  font-size: 15px;
  color: #878787;
  padding-bottom: 20px;
  max-width: 400px;
  text-align: left;
}

.resellModalContainer .decisionButton {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.decisionButton .cancel {
  text-transform: uppercase;
  /* font-weight: bold; */
  color: #6e6e6e;
  font-size: 14px;
}

.decisionButton .accept {
  text-transform: uppercase;
  /* font-weight: bold; */
  color: #389f70;
  font-size: 14px;
}

.decisionButton .accept:hover {
  font-weight: bold;
  cursor: pointer;
}

.decisionButton .cancel:hover {
  font-weight: bold;
  cursor: pointer;
}
