.company-profile-container {
  .company-image-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .placeholder-logo {
      border: none;
      width: 80px;
      height: 80px;
      margin: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: #323b47;
      color: #ffffff;

      .image-label {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-self: stretch;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .logo-text{
          margin-top: 3px;
        }
      }
    }

    .image:hover {
      cursor: pointer;
    }

    .placeholder-logo > input {
      visibility: hidden;
      width: 0;
      height: 0;
    }

    .logo-container {
      min-height: 80px;
      width: 100px;
      margin: 20px;
      position: relative;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > img {
        width: 100%;
      }
    }
    .logo-container > input {
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }

  .address-container {
    font-family: inherit;
    margin: 0px;
  }
}

.company-details-edit-icon-container {
  height: 24px;
  width: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .company-details-edit-icon {
    fill: #fff;
    height: 24px;
    width: 24px;
  }
}

.company-details-edit-profile-icon {
  position: absolute;
  fill: #fff;
  height: 24px;
  width: 24px;
  bottom: 5px;
  right: 5px;
  background: #0000008c;
  border-radius: 50%;
  padding: 5px;
}

.company-details-edit-profile-icon:hover {
  cursor: pointer;
}

.errorIcon {
  width: 60px;
  height: 60px;
  fill: #d24b4b;
}
