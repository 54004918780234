@mixin filter-section-title() {
  color: #000;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 22px;
}

@mixin field-label() {
  color: #000;
  font-weight: bold;
  font-size: 15px;
  margin-right: 10px;
}
