.settings-data-container {
  &.MuiButtonBase-root {
    width: calc(100% - 2 * 20px);
    background-color: #242c36;
    padding: 0px 20px;

    &:hover {
      background-color: #0d131b;
    }

    &.settings-data--click {
      cursor: pointer;
    }

    &.settings-data--disable {
      pointer-events: none;
    }

    .settings-data-row-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      border-bottom: 1px solid #3a424e;
      padding: 15px 0px;

      .icon-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1 0 auto;

        & > svg {
          fill: white;
          width: 20px;
        }
      }

      .settings-data-text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 0 1 auto;

        .settings-data-text--title {
          color: white;
          margin: 0px;
          font-size: 1.1rem;
          word-wrap: break-word;
          word-break: break-word;
        }

        .settings-data-text--description {
          color: #9caabd;
          text-align: left;
          font-size: 0.9rem;
          max-width: 100%;
          word-wrap: break-word;
          word-break: break-word;
        }

        .settings-data-text--title + .settings-data-text--description {
          margin-top: 10px;
        }
      }
    }
  }
}
