.productTagRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;

  .tagContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 0;

    .tag {
      max-width: 100%;
      background-color: #fff;
      border-radius: 20px;
      padding: 10px 15px;
      box-shadow: 0 2px 4px rgba(203, 203, 203, 0.5);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .tagStrikethrough {
        text-decoration: line-through;
      }

      .tagText {
        font-size: 16px;
        color: #000;
        font-weight: 800;
      }
    }
  }

  .deleteTagIconContainer {
    cursor: pointer;
    padding: 0 10px;

    .deleteIcon {
      width: 30px;
      height: 30px;
    }

    &:hover {
      .deleteIcon {
        fill: #e26767;
      }
    }
  }

  .reorderIconContainer {
    padding: 0 10px;
    cursor: pointer;

    .reorderIcon {
      width: 25px;
      height: 25px;
    }

    &:hover {
      .reorderIcon {
        fill: #729d7d;
      }
    }
  }
}
