@import '~qs-assets/Colors';
@import '~qs-assets/PositionVars.scss';

.appWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .screenWrapper {
    flex: 1 1;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: $app-zIndex;

    .authNotReady {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      background-color: $catalogue-screen-background;
      align-self: stretch;
    }
  }
}
