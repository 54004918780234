@import '~grapesjs/dist/css/grapes.min.css';
@import '~grapick/dist/grapick.min.css';

[data-quicksell-component-type="wrapper"],
[data-gjs-type="wrapper"] {
  position: relative;
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]::after {
  font-family: inherit;
  background: rgba(51, 51, 51, 0.9);
  background: rgba(55, 61, 73, 0.9);
  border-radius: 3px;
  bottom: 100%;
  color: #fff;
  content: attr(data-tooltip);
  display: block;
  font-size: 12px;
  left: 50%;
  line-height: normal;
  max-width: 32rem;
  opacity: 0;
  overflow: hidden;
  padding: 0.6rem 1rem;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  transition: all 0.216s ease;
  z-index: 99;
}

[data-tooltip]:focus::after,
[data-tooltip]:hover::after {
  opacity: 1;
  -webkit-transform: translate(-50%, -0.5rem);
  -ms-transform: translate(-50%, -0.5rem);
  transform: translate(-50%, -0.5rem);
}

[data-tooltip][disabled],
[data-tooltip].disabled {
  pointer-events: auto;
}

[data-tooltip-pos=right]::after {
  bottom: 50%;
  left: 100%;
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

[data-tooltip-pos=right]:focus::after,
[data-tooltip-pos=right]:hover::after {
  -webkit-transform: translate(0.5rem, 50%);
  -ms-transform: translate(0.5rem, 50%);
  transform: translate(0.5rem, 50%);
}

[data-tooltip-pos=bottom]::after {
  bottom: auto;
  top: 100%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

[data-tooltip-pos=bottom]:focus::after,
[data-tooltip-pos=bottom]:hover::after {
  -webkit-transform: translate(-50%, 0.5rem);
  -ms-transform: translate(-50%, 0.5rem);
  transform: translate(-50%, 0.5rem);
}

[data-tooltip-pos=left]::after {
  bottom: 50%;
  left: auto;
  right: 100%;
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

[data-tooltip-pos=left]:focus::after,
[data-tooltip-pos=left]:hover::after {
  -webkit-transform: translate(-0.5rem, 50%);
  -ms-transform: translate(-0.5rem, 50%);
  transform: translate(-0.5rem, 50%);
}

.toast-title {
  font-weight: 700
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word
}

.toast-message a,
.toast-message label {
  color: #FFF
}

.toast-message a:hover {
  color: #CCC;
  text-decoration: none
}

.toast-close-button {
  position: relative;
  right: -.3em;
  top: -.3em;
  float: right;
  font-size: 20px;
  font-weight: 700;
  color: #FFF;
  -webkit-text-shadow: 0 1px 0 #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: .8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  line-height: 1
}

.toast-close-button:focus,
.toast-close-button:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40)
}

.rtl .toast-close-button {
  left: -.3em;
  float: left;
  right: .3em
}

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%
}

.toast-top-left {
  top: 12px;
  left: 12px
}

.toast-top-right {
  top: 12px;
  right: 12px
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px
}

#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none
}

#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

#toast-container>div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999;
  -webkit-box-shadow: 0 0 12px #999;
  box-shadow: 0 0 12px #999;
  color: #FFF;
  opacity: .8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80)
}

#toast-container>div.rtl {
  direction: rtl;
  padding: 15px 50px 15px 15px;
  background-position: right 15px center
}

#toast-container>div:hover {
  -moz-box-shadow: 0 0 12px #000;
  -webkit-box-shadow: 0 0 12px #000;
  box-shadow: 0 0 12px #000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer
}

#toast-container>.toast-info {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=) !important
}

#toast-container>.toast-error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=) !important
}

#toast-container>.toast-success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==) !important
}

#toast-container>.toast-warning {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=) !important
}

#toast-container.toast-bottom-center>div,
#toast-container.toast-top-center>div {
  width: 300px;
  margin-left: auto;
  margin-right: auto
}

#toast-container.toast-bottom-full-width>div,
#toast-container.toast-top-full-width>div {
  width: 96%;
  margin-left: auto;
  margin-right: auto
}

.toast {
  background-color: #030303
}

.toast-success {
  background-color: #51A351
}

.toast-error {
  background-color: #BD362F
}

.toast-info {
  background-color: #2F96B4
}

.toast-warning {
  background-color: #F89406
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000;
  opacity: .4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40)
}

@media all and (max-width:240px) {
  #toast-container>div {
    padding: 8px 8px 8px 50px;
    width: 11em
  }

  #toast-container>div.rtl {
    padding: 8px 50px 8px 8px
  }

  #toast-container .toast-close-button {
    right: -.2em;
    top: -.2em
  }

  #toast-container .rtl .toast-close-button {
    left: -.2em;
    right: .2em
  }
}

@media all and (min-width:241px) and (max-width:480px) {
  #toast-container>div {
    padding: 8px 8px 8px 50px;
    width: 18em
  }

  #toast-container>div.rtl {
    padding: 8px 50px 8px 8px
  }

  #toast-container .toast-close-button {
    right: -.2em;
    top: -.2em
  }

  #toast-container .rtl .toast-close-button {
    left: -.2em;
    right: .2em
  }
}

@media all and (min-width:481px) and (max-width:768px) {
  #toast-container>div {
    padding: 15px 15px 15px 50px;
    width: 25em
  }

  #toast-container>div.rtl {
    padding: 15px 50px 15px 15px
  }
}

.icons-flex {
  background-size: 70% 65% !important;
  height: 15px;
  width: 17px;
  opacity: 0.9;
}

.icon-dir-row {
  background: url("./Icons/flex-dir-row.png") no-repeat center;
}

.icon-dir-row-rev {
  background: url("./Icons/flex-dir-row-rev.png") no-repeat center;
}

.icon-dir-col {
  background: url("./Icons/flex-dir-col.png") no-repeat center;
}

.icon-dir-col-rev {
  background: url("./Icons/flex-dir-col-rev.png") no-repeat center;
}

.icon-just-start {
  background: url("./Icons/flex-just-start.png") no-repeat center;
}

.icon-just-end {
  background: url("./Icons/flex-just-end.png") no-repeat center;
}

.icon-just-sp-bet {
  background: url("./Icons/flex-just-sp-bet.png") no-repeat center;
}

.icon-just-sp-ar {
  background: url("./Icons/flex-just-sp-ar.png") no-repeat center;
}

.icon-just-sp-cent {
  background: url("./Icons/flex-just-sp-cent.png") no-repeat center;
}

.icon-al-start {
  background: url("./Icons/flex-al-start.png") no-repeat center;
}

.icon-al-end {
  background: url("./Icons/flex-al-end.png") no-repeat center;
}

.icon-al-str {
  background: url("./Icons/flex-al-str.png") no-repeat center;
}

.icon-al-center {
  background: url("./Icons/flex-al-center.png") no-repeat center;
}

[data-tooltip]::after {
  background: rgba(51, 51, 51, 0.9);
}

// .gjs-mdl-container:has(.tui-image-editor-container) {
//   height: 100%;
//   width: 100%;
//   z-index: 1005;
//   padding: 0px;
//   margin: 0px;
//   overflow: hidden;
//   font-family: inherit;

//   .gjs-mdl-dialog {
//     height: 100%;
//     min-height: 100%;
//     width: 100%;
//     min-width: 100%;
//     border-radius: 0px;
//     padding: 0px;
//     margin: 0px;

//     .gjs-mdl-header {
//       height: 42px;
//       width: 100%;
//       padding: 0px;

//       .gjs-mdl-title {
//         height: 100%;
//         width: calc(100% - 42px - 36px);
//         padding: 0px 24px;
//         font-size: 16px;
//         line-height: 42px;
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//       }

//       .gjs-mdl-btn-close {
//         height: 42px;
//         width: 42px;
//         padding: 0px;
//         margin: 0px;
//         z-index: 1005;
//         top: 0px;
//         right: 0px;
//         font-size: 56px;
//         line-height: 26px;
//         cursor: pointer;
//       }
//     }

//     .gjs-mdl-content {
//       height: calc(100% - 42px);
//       padding: 0px;

//       #gjs-mdl-c {
//         height: 100%;
//         width: 100%;

//         & > div:not([class]) {
//           height: 100%;
//           width: 100%;

//           .tui-image-editor-container {
//             height: 100% !important;
//             width: 100% !important;
//           }

//           .tui-image-editor__apply-btn {
//             top: 8px !important;
//             right: 8px !important;
//           }
//         }
//       }
//     }
//   }
// }

.gjs-pn-commands {
  min-height: 40px;
}

#gjs-sm-float {
  display: none;
}

.gjs-logo-version {
  background-color: #756467;
}

.gjs-pn-btn.gjs-pn-active {
  box-shadow: none;
}

.CodeMirror {
  min-height: 450px;
  margin-bottom: 8px;
}

.grp-handler-close {
  background-color: transparent;
  color: #ddd;
}

.grp-handler-cp-wrap {
  border-color: transparent;
}

.gjs-editor-cont ::-webkit-scrollbar {
  background-color: #121923;
  height: 4px;
  width: 8px;
}

.gjs-editor-cont ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.25);
  border-radius: 4px;
}

.gjs-editor-cont ::-webkit-scrollbar-track {
  background-color: #121923;
}