$green: #489F6E;
$white: #ffffff;

.edit-social-link {
  &.title {
    color: $white;
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    padding: 24px 25px;
  }

  &.input-container {
    display: flex;
    border-bottom: 1px solid $white;
    margin: 0px 25px 40px;

    .input-box {
      flex: 1;
      font-size: 16px;
      padding: 14px 0px;
      color: $white;
      outline: none;
      border: none;
      background-color: transparent;

      &::placeholder {
        color: #424f61;
      }
    }
  }

  &.button-container {
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    font-size: 16px;
    border-top: 1px solid #39424E;

    .button {
      flex: 1;
      border: none;
      color: #758496;
      background-color: transparent;
      text-align: left;
      padding: 21px 25px;
      text-transform: uppercase;
    }

    .button-reset {
      text-align: left;
      color: #758496;
    }

    .button-plain {
      color: $green;
      text-align: right;
    }
  }
}