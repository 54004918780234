@import '~qs-assets/Colors';

.settingsList {
  max-width: 650px;
  display: flex;
  flex-direction: row;
  overflow: auto;
  flex-shrink: 0;
  box-sizing: border-box;
  background-color: $settings-panel-background;
  border-right: 1px solid black;
  width: 50%;

  .configureList {
    width: 60%;
    display: flex;
    flex-direction: column;
    color: #586577;
    border-right: 1.5px solid $settings-panel-active-background;

    .configureTitle {
      margin: 30px;
      font-weight: 600;
      position: absolute;
      font-size: 15px;
      text-transform: uppercase;
    }
    .header {
      position: relative;
      top: 70px;
    }
    .selected {
      color: white;

      background-color: $settings-panel-active-background;
      font-weight: 600;
      padding: 12px;
      padding-left: 27px;
      left: 30px;
      display: flex;
      align-content: flex-start;
      font-size: 18px;
      text-align: left;
      width: 100%;
    }
    .configureMenu {
      &:hover {
        background-color: #0d131b;
      }
      background-color: $settings-panel-background;
      font-weight: 600;
      padding: 12px;
      padding-left: 27px;
      left: 30px;
      color: #586577;
      display: flex;
      align-content: flex-start;
      font-size: 18px;
      text-align: left;
      width: 100%;
    }
  }

  .configureSettingsList {
    overflow-y: scroll;
    display: flex;
    width: 100%;
    flex-direction: column;
    .sectionTitle {
      margin: 20px;
      margin-left: 25px;
      font-size: 14px;
      text-transform: uppercase;
      margin-top: 30px;
      color: #23e49a;
    }
    .settings-row-container {
      &:hover {
        background-color: #0d131b;
      }

      &.selected-setting {
        background: $settings-panel-active-background;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    .singleRow {
      color: white;
      padding: 20px 0px;
      margin: 0px 27px;
    }

    .settings-app-version {
      margin: 20px;
      color: #586577;
      font-size: 12px;
      text-align: center;
      text-transform: uppercase;
    }
  }
}
