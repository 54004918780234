.productTagsModal {
  width: 500px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;

  .header {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e4e4e4;

    .headerLeft {
      display: flex;
      flex-direction: row;
      align-items: center;

      .headerIcon {
        width: 20px;
        height: 20px;
      }

      .headerText {
        margin-left: 15px;
        font-size: 18px;
        color: #000;
        font-weight: 700;
      }
    }

    .headerRight {
      display: flex;
      flex-direction: row;
      align-items: center;

      .closeButton {
        cursor: pointer;
        padding: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .closeButtonIcon {
          width: 25px;
          height: 25px;
        }

        &:hover {
          .closeButtonIcon {
            fill: #000;
          }
        }
      }
    }
  }

  .content {
    .contentScroll {
      background-color: rgba(236, 246, 238, 0.65);
      overflow: auto;

      .selectedContent {
        .selectedTagsContainer {
          .selectedTagsList {
            .selectedAddTagButton {
              cursor: pointer;
              display: inline-block;
              margin-left: 20px;
              margin-bottom: 20px;
              margin-top: 20px;
              background-color: #fff;
              border-radius: 20px;
              padding: 10px 15px;
              box-shadow: 0 2px 4px rgba(203, 203, 203, 0.5);

              .selectedAddTagButtonText {
                font-size: 19px;
                color: #33ad61;
                font-weight: 800;
              }
            }
          }
        }
      }

      .noSelectedTagsContainer {
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .noSelectedTagsIcon {
          width: 70px;
          height: 70px;
        }

        .noSelectedTagsText {
          margin-top: 20px;
          text-align: center;
          color: #7e8f82;
          font-size: 18px;
        }

        .noSelectedAddTagButton {
          cursor: pointer;
          margin-top: 40px;
          background-color: #fff;
          border-radius: 20px;
          padding: 10px 15px;
          box-shadow: 0 2px 4px rgba(203, 203, 203, 0.5);

          &:hover {
            box-shadow: 0 5px 10px hsla(0, 0%, 79.6%, 0.5);
          }

          .noSelectedAddTagButtonText {
            font-size: 19px;
            color: #000;
            font-weight: 800;
          }
        }
      }
    }

    .contentHeader {
      padding: 10px 0;
      background-color: #fff;

      .contentHeaderTitle {
        padding: 0 20px;
        color: #000;
        font-weight: 800;
        font-size: 14px;
      }

      .contentHeaderSubtitle {
        padding: 0 20px;
        margin-top: 2px;
        color: #c3c3c3;
        font-size: 14px;
      }

      .unselectedTagsContainer {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 15px;

        .unselectedTag {
          cursor: pointer;
          align-self: center;
          margin: 5px;
          padding: 5px 10px;
          border-radius: 15px;
          border: 1px dashed #7cd3a3;

          .unselectedTagText {
            color: #000;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.activeProductTagSortRow {
  z-index: 1001;
  background-color: rgba(227, 237, 229, 0.65);
}

//.tags-toast-error-icon {
//  background-color: #BD362F;
//  padding-left: 15px;
//  width: auto;
//  background-image: url('../../../../Assets/error.svg');
//}
// TODO: toast is fucked here
