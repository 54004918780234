$bottomSeperator: 1rem;
$errorColor: #fc5959;

.suggestion-feedback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .feedback-opertion-status {
    color: white;
    font-size: 1.2rem;
  }

  .suggestion-feedback-box {
    background-color: white;
    border-radius: 10px;
    padding: 30px;
  }

  .bottom-seperator {
    margin: 0px;
    margin-bottom: $bottomSeperator;
  }

  .editable-content {
    height: 100px;
    overflow: auto;
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 400px;
    max-width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;

    &.editable-error {
      border: 1px solid $errorColor;
    }
  }

  .editable-error-msg {
    color: $errorColor;
  }

  .content {
    outline: none;
    flex-grow: 1;
    overflow-wrap: break-word;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .submit-feedback {
      border-radius: 5px;
      background-color: #59c18f;
      color: white;
      border: none;
      padding: 10px 20px;
      font-size: 1rem;
    }
  }
}
