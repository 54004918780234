.CatalogueSettingWrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
  padding-top: 12px;
  padding-bottom: 12px;
}

.CatalogueSettingWrapper:last-of-type {
  border-bottom: none;
}

.CatalogueSettingWrapper .CatalogueSettingContainer {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.CatalogueSettingMeta {
  display: flex;
  flex-direction: column;
  flex: 1;
  cursor: pointer;
  overflow: auto;
}

.CatalogueSettingMeta:hover .CatalogueSettingDescription {
  color: #000;
}

.CatalogueSettingTitle {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 15px;
  cursor: pointer;
  max-width: 80%;
}

.CatalogueSettingDescription {
  font-size: 13px;
  color: #666;
  padding-right: 30px;
}

.CatalogueSettingWrapper .inventoryManagedFrom {
  margin-top: 10px;
  font-size: 14px;
}

.CatalogueSettingWrapper .reset {
  color: #2e2e9f;
}

.CatalogueSettingWrapper .reset:hover {
  cursor: pointer;
}

.CatalogueSwitch .loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-width: 58px;
}

.CatalogueSwitch .editIconContainer {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
