@mixin field-styles() {
  background-color: transparent;
  font-size: 22px;
  color: #ffffff;
  border-bottom: 2px solid #414a5a;

  &::placeholder {
    color: #505e6f;
  }

  &:focus {
    border-bottom-color: #ffffff;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

@mixin input-field() {
  @include field-styles();
  width: 100%;
  height: 43px;
  padding-top: 0px;
  padding-bottom: 0px;
}

@mixin text-area-field() {
  border: none;
  //Include after border none so that bottom border does not get set to none
  @include field-styles();
  margin-top: 10px;
  resize: none;
  width: 100%;
}

@mixin input-field-container() {
  margin-top: 5px;
  padding-right: 5px;
  position: relative;
}

$container-border-left: 1px solid #181f27;
$header-border-left: 1px solid #000000;
$container-base-zIndex: 0;
$add-product-options-zIndex: $container-base-zIndex + 1;
$image-upload-zIndex: $container-base-zIndex + 3;
$delete-options-zIndex: $container-base-zIndex + 4;
$variant-view-zIndex: $container-base-zIndex + 5;
$field-seperator: 10px;
$field-row-padding: 20px;
