@mixin shimmer-animate($start-color, $mid-color, $end-color) {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, $start-color 4%, $mid-color 25%, $end-color 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}
